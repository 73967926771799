
/*
 * sizes and positions
 */
a.datepicker-button {
  cursor: pointer;
  &.default {
    &:hover,
    &:focus {
      background-color: #DDD;
    }
  }
}
.datepicker-calendar {
  margin: 0 10px 10px 0;
  font-size: .9em;
  padding: 2px;
  position: absolute;
  width: 261px;
  border: 1px solid #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 50;
  .datepicker-month-wrap {
    margin: 0;
    padding-top: 1px;
    text-align: center;
    height: 30px;
  }
  .datepicker-month-fast-prev.disabled,
  .datepicker-month-fast-next.disabled ,
  .datepicker-month-prev.disabled,
  .datepicker-month-next.disabled {
    border: none;
    cursor: default;
    color: #999;
  }
  .datepicker-month-fast-prev,
  .datepicker-month-fast-next,
  .datepicker-month-prev,
  .datepicker-month-next {
    cursor: pointer;
    margin: 3px;
    width: 24px;
    height: 24px;
    padding-top: 3px;
  }
  .datepicker-month-fast-prev {
    padding-right: 2px;
  }
  .datepicker-month-fast-next {
    padding-left: 2px;
  }
  .datepicker-month-fast-prev.enabled:hover,
  .datepicker-month-fast-prev.enabled:focus,
  .datepicker-month-fast-next.enabled:hover,
  .datepicker-month-fast-next.enabled:focus,
  .datepicker-month-prev.enabled:hover,
  .datepicker-month-prev.enabled:focus,
  .datepicker-month-next.enabled:hover,
  .datepicker-month-next.enabled:focus {
    margin-top: 2px;
    border: 1px solid #800;
    border-radius: 4px;
  }
  .datepicker-month {
    margin: 3px 56px 2px 56px;
    height: 24px;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    cursor: pointer;
    padding-top: 1px;
    &:after {
      font-family: 'Glyphicons Halflings';
      font-size: 0.6em;
      display: inline-block;
      content: "\e252";
    }
    &:hover,
    &:focus {
      padding-top: 0;
      border: 1px solid #800;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
  }
  table.datepicker-grid {
    width: 100%;
    font-size: 1.2em;
    text-align: center;
    &.rtl {
      direction: rtl;
    }
    &:focus {
      outline: none;
    }
    th,
    td{
      text-align: center;
      padding: 0;
    }
    th {
      height: 30px;
      abbr {
        border: none;
      }
    }
    td {
      border: 1px solid #999;
      cursor: pointer;
      &.unselectable {
        cursor: default;
      }
      &.day {
        height: 30px;
      }
      &.month {
        height: 60px;
      }
      &.year {
        height: 45px;
      }
      &.curDay,
      &.curMonth,
      &.curYear {
        border: 1px solid #999;
      }
      &.empty {
        border: 1px solid #999;
        cursor: default;
      }
    }
  }
  .offscreen {
    position: absolute;
    left: -200em;
    top: -100em;
  }
  button.datepicker-close {
    height: 30px;
    width: 100%;
    font-weight: bold;
    margin-top: 1px;
    border: 1px solid #eee;
    border-radius: 2px;
  }

  /****************************
  * default theme colors
  ***************************/

  &.default {
    background-color: #FFF;
    button.datepicker-close:focus,
    button.datepicker-close:hover,
    .datepicker-month-fast-next.enabled:focus,
    .datepicker-month-fast-next.enabled:hover,
    .datepicker-month-fast-prev.enabled:focus,
    .datepicker-month-fast-prev.enabled:hover,
    .datepicker-month-next.enabled:focus,
    .datepicker-month-next.enabled:hover,
    .datepicker-month-prev.enabled:focus,
    .datepicker-month-prev.enabled:hover,
    .datepicker-month:focus,
    .datepicker-month:hover {
      background-color: #EEE;
      border-color:  #999;
      border: none;
      margin: 0;
      padding: 0;
    }
    .datepicker-month-fast-next,
    .datepicker-month-fast-prev,
    .datepicker-month-next,
    .datepicker-month-prev {
      margin: 0;
      padding: 0;
    }

    table.datepicker-grid {
      font-size: $dp-grid-size;
      text-transform: uppercase;
      letter-spacing: $dp-month-l-spacing;
      &:focus {
        outline: none;
      }
      th {
        background-color: transparent;
        border:  none;
      }
      td {
        border:  none;
        color: #000;
        background: transparent;
        span {
          display: inline-block;
          width: $dp-current-size;
          height: $dp-current-size;
          line-height: $dp-current-size;
          border-radius: 50px;
          text-align: center;
          transition: all 0.3s;
        }
        &:hover {
          background: transparent;
          span {
            background: $neutral-1-a1;
          }
        }
        &.unselectable {
          color: #999;
        }
        &.curDay,
        &.curMonth,
        &.curYear {
          background: transparent;
          span {
            background: $lightgrey-b1;
          }
        }
        &.empty {
          border:  none;
          color: #CCC;
          &:hover {
            background: transparent;
            span {
              background: $primary-a6;
              color: #FFF;
            }
          }
        }
        &.selectable:hover,
        &.selectable.focus {
          background-color: transparent;
        }
        &.selectable.focus {
          span {
            background: $primary-a6;
            color: #FFF;
          }
        }
      }
    }
    tr.datepicker-weekdays {
      border: none;
    }
    button.datepicker-close {
      background-color: #DDD;
      border-color:  #999;
      color: #000;
    }
  }
}

.datepicker-overlay {
  background: #777;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  height: 100%;
  min-height: 100%;
  z-index: 40;
}

abbr[data-original-title], abbr[title] {
  text-decoration: none;
  cursor: default;
}

.it-datepicker-wrapper {
  position: relative;
  // calendar
  input[type="time"] {
    color: $dp-label-color;
  }
  label.pickerlabel {
    color: $gray-secondary;
    font-weight: 600;
    line-height: $input-height;
    transition: .2s ease-out;
    top: $input-spacing-t;
    font-size: $small-font-size;
    cursor: text;
    display: block;
    width: 100%;
    padding: 0 $input-spacing-x;
    z-index: 6;
  }
  .datepicker-calendar {
    box-shadow: $dp-shadow;
    border-radius: 0;
    border: none;
    width: 100%;
    border-top: 2px solid $dp-label-color;
    margin-top: -8px;
    color: $dp-label-color;
    padding: $v-gap*3;
    &[aria-hidden=true] {
      display: inherit;
    }
    .datepicker-month-wrap {
      padding: 0;
      color: $dp-label-color;
      margin-bottom: $v-gap*2;
      .datepicker-month {
        font-size: $dp-month-size;
        letter-spacing: $dp-month-l-spacing;
        text-transform: uppercase;
        &:after {
          display: none;
        }
      }
      .icon-right {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px 0 3px 6px;
        border-color: transparent transparent transparent #000;
        display: inline-block;
        transform: translateY(-3px);
      }
      .icon-left {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px 6px 3px 0;
        border-color: transparent #000 transparent transparent;
        display: inline-block;
        transform: translateY(-3px);
      }
      .datepicker-month-fast-next {
        float: right;
        display: inline-block;
        min-width: $v-gap*4;
        svg {
          &:first-child {
            margin-right: -$v-gap;
          }
        }
      }
      .datepicker-month-next {
        float: right;
      }
      .datepicker-month-fast-prev {
        float: left;
        display: inline-block;
        min-width: $v-gap*4;
        svg {
          &:last-child {
            margin-left: -$v-gap;
          }
        }
      }
      .datepicker-month-prev {
        float: left;
      }
    }
    .datepicker-month {
      margin: 0;
      padding: 0;
    }
    tr.datepicker-weekdays {

    }
  }
  .input-group {
    //border-bottom:$dp-border-bottom;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: $dp-label-color;
      transform-origin: bottom;
      transition: all 0.3s;
      left: 0;
    }
    .it-date-datepicker {
      border-bottom: none;
      background: transparent;
      &::placeholder {
        color: $dp-label-color;
      }
    }
    // button
    .datepicker-button {
      padding: 0;
      all: unset;
      margin-right: $v-gap*2;
      position: relative;
      height: 40px;
      cursor: pointer;
      &:hover {
        all: unset;
        margin-right: $v-gap*2;
        height: 40px;
        .icon {
          fill: $primary-a6;
        }
      }
      .icon {
        fill: $dp-label-color;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s;
      }
    }
  }

}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
